/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useQuery, useMutation } from '@tanstack/react-query';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';
import { GET_NURSERIES_KEY, UPSERT_NURSERY_KEY, DELETE_NURSERY_KEY } from '../queryConstants';
import { queryErrorHandler, querySuccessHandler, queryDeleteSuccessHandler } from '../queryStateHandlers';
import { INursery } from '../../../@types/model/masterData/nursery/nursery';
import NurseryHttpService from '../../../service/http/masterData/nurseryHttpService';
import { INurseryUpsert } from '../../../@types/model/masterData/nursery/nurseryUpsert';

export const useGetNurseries = () => {
    const query = useQuery<Array<INursery>, Error>({
        queryKey: [GET_NURSERIES_KEY],
        queryFn: ({ signal }) => NurseryHttpService.getList(signal),
        onError: queryErrorHandler('Error loading Nurseries.'),
    });

    return query;
};

export const useHandleNurseryUpsert = () => {
    const result = useMutation<INursery, Error, { upsert : INurseryUpsert; type : UpsertTypeEnum }>({
        mutationKey: [UPSERT_NURSERY_KEY],
        mutationFn: (args : { upsert : INurseryUpsert; type : UpsertTypeEnum }) => NurseryHttpService.upsert(args.upsert, args.type),
        onError: queryErrorHandler('Error creating/updating Nursery.'),
        onSuccess: querySuccessHandler([GET_NURSERIES_KEY], 'Nursery updated successfully.'),
    });

    return result;
};

export const useHandleNurseryDelete = () => {
    const result = useMutation<void, Error, number>({
        mutationKey: [DELETE_NURSERY_KEY],
        mutationFn: (nurseryId : number) => NurseryHttpService.delete(nurseryId),
        onError: queryErrorHandler('Error deleting Nursery.'),
        onSuccess: queryDeleteSuccessHandler([GET_NURSERIES_KEY], 'Nursery inactivated successfully.'),
    });

    return result;
};