import { MutationKey, useQueryClient } from '@tanstack/react-query';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import GeneralThunks from '../../store/general/thunk';
import { ArrayHelper, IBaseUser } from '@zz2/zz2-ui';
import { IUserToken } from '../../@types/model/right/userToken/userTokenModels';
import AuthActions from '../../store/auth/actions';
import { setLocalStorageSession } from '../../service/localStorage/localStorageService';

export const queryErrorHandler = (errorMessage : string) => {
    const dispatch = useAppDispatch();

    const handleError = (error : Error) => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: errorMessage,
            ex: error,
        }));
    };
    
    return handleError;
};

export const querySuccessHandler = <T extends { id : number }>(getKey : MutationKey, successMessage : string, getKeyFilterId ?: string) => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const handleOnSuccess = (result : T | Array<T>) => {
        const isArrayType = Array.isArray(result);

        let queryKey = getKey;

        if (getKeyFilterId) {
            const dataModel = isArrayType ? result[0] : result;

            if (getKeyFilterId in dataModel) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                queryKey = [getKey, (dataModel as any)[getKeyFilterId]];
            }
        }
        
        queryClient.setQueryData(queryKey, (cachedData ?: Array<T>) => {
            if (!cachedData) return [result];

            if (isArrayType) {
                return ArrayHelper.upsertElements(cachedData, [...result], (a : T, b : T) => a.id == b.id);
            } else {
                return ArrayHelper.upsertElement(cachedData, result, (x => x.id === result.id));
            }
        });

        dispatch(GeneralThunks.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};

export const queryDeleteSuccessHandler = <T extends { id : number; isActive : boolean }>(getKey : MutationKey, successMessage : string) => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const handleOnSuccess = (data : void, deletedId : number) => {
        queryClient.setQueryData(getKey, (cachedData ?: Array<T>) => {
            if (!cachedData) return [cachedData];

            const deletedData = cachedData.find(x => x.id === deletedId);

            if (deletedData) {
                deletedData.isActive = false;
                return ArrayHelper.upsertElement(cachedData, deletedData, (x => x.id === deletedId));
            }
            return;
        });

        dispatch(GeneralThunks.showSuccessSnackbar(successMessage));
    };
    return handleOnSuccess;
};

export const queryNoReturnContentSuccessHandler = (successMessage : string) => {
    const dispatch = useAppDispatch();
    
    const handleOnSuccess = () => {
        dispatch(GeneralThunks.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};

export const queryUpdateSessionSuccessHandler = (successMessage : string) => {
    const dispatch = useAppDispatch();
    const session = useAppSelector<IUserToken | null>(x => x.auth.session);

    const handleOnSuccess = async (data : IBaseUser) => {
        if (!session) return;

        const updatedSession = {
            ...session,
            user: data,
        };

        dispatch(AuthActions.setSession(updatedSession));
        await setLocalStorageSession(updatedSession);
        
        dispatch(GeneralThunks.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};