import React, { useEffect, useMemo } from 'react';
import { useHandleFreshServiceTicketUpsert } from '../../hooks/query/freshService/freshServiceQueries';
import { IOptionType, ProjectDialog } from '@zz2/zz2-ui';
import FreshServiceTicketModelHelper from '../../@types/model/freshService/freshServiceTicketModelHelper';
import { useLocation } from 'react-router';
import EnvHelper from '../../service/helper/envHelper';
import { VERSION } from '../../version';
import { useAppSelector } from '../../@types/redux';
import FreshServiceTicketForm from './FreshServiceTicketDialogForm';
import { useGetUserEmailViews, useGetUsers } from '../../hooks/query/user/userQueries';
import { IFreshServiceTicketFormValues } from '../../@types/model/freshService/freshServiceTicketFormValues';

interface IFreshServiceTicketEditDialogProps {
    isOpen : boolean;
    onDismiss : () => void;
    title ?: string;
    description ?: string;
}

const FreshServiceTicketCreationDialog = (props : IFreshServiceTicketEditDialogProps) : React.ReactElement => {
    const location = useLocation();
    const currentUser = useAppSelector(x => x.auth.session?.user);

    const { isLoading: isUpsertingFreshServiceTicket, mutate: upsertFreshServiceTicket, isSuccess: upsertSuccess } = useHandleFreshServiceTicketUpsert();
    const { isLoading: isLoadingUsers, data: users } = useGetUserEmailViews();

    useEffect(() => {
        if (upsertSuccess) {
            props.onDismiss();
        }
    }, [upsertSuccess]);

    const onSubmit = async (values : IFreshServiceTicketFormValues) : Promise<void> => {
        const upsert = FreshServiceTicketModelHelper.createUpsert(values);
        
        upsertFreshServiceTicket(upsert);
    };

    const userOptions = useMemo<Array<IOptionType>>(() => {
        return users?.map(x => {
            return {
                label: x.name,
                value: x.id
            };

        }) ?? [];

    }, [users]);

    const getInitialFormValues = () : IFreshServiceTicketFormValues => {
        const systemName = EnvHelper.getEnvName();

        return FreshServiceTicketModelHelper.createFormValues(
            systemName, 
            VERSION.version, 
            location.pathname, 
            currentUser?.name ?? '',
            currentUser?.email ?? '',
            props.description
        );
    };

    return (
        <ProjectDialog
            title={'ZZ2FRESHSERVICE SUPPORT TICKET'}
            isLoadingCircular={isUpsertingFreshServiceTicket || isLoadingUsers}
            isOpen={props.isOpen}
            fullWidth
            maxWidth={'sm'}
            onClose={props.onDismiss}>
            <FreshServiceTicketForm initialValues={getInitialFormValues()} onSubmit={onSubmit} onCancel={props.onDismiss} userOptions={userOptions} />
        </ProjectDialog>
    );
};


export default FreshServiceTicketCreationDialog;