import { GoogleOAuthProvider } from '@react-oauth/google';
import { ConfirmationDialog, CustomTooltip, GoogleSignInButton, IBaseUser, IGoogleLoginError, IGoogleLoginSuccess, IOptionType, MuiAutocompleteSelect, PillButton, ProjectDialog } from '@zz2/zz2-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../@types/redux';
import { useGetUser, useHandleLinkGoogleAccount, useHandleUnlinkGoogleAccount } from '../../hooks/query/user/userQueries';
import GeneralThunks from '../../store/general/thunk';
import { Button, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { getUserSelectedNurseriesLocalStorage } from '../../service/localStorage/localStorageService';
import { useGetNurseries } from '../../hooks/query/masterData/nurseryQueries';
import { IUser } from '../../@types/model/right/user/userModel';
import OptionType from '../../@types/model/OptionType';

interface IBaseUserSettingsProps {
    isOpen : boolean;
    onClose : () => void;
    onLogout : () => void;
    currentUser : IUser;
}

const UserSettings = (props : IBaseUserSettingsProps) : React.ReactElement => {
    const dispatch = useAppDispatch(); 
    const selectedNurseries = getUserSelectedNurseriesLocalStorage();

    const [selectedNurseryOptions, setSelectedNurseryOptions] = useState<Array<IOptionType>>([]);
    const [unlinkEmailDialogOpen, setUnlinkEmailDialogOpen] = useState<boolean>(false);
    const [isLogOutDialogOpen, setIsLogOutDialogOpen] = useState<boolean>(false);

    const { isLoading: isLoadingNurseries, data: nurseries } = useGetNurseries();
    const { isLoading: isUnlinkingEmail, mutate: unlinkEmail, isSuccess: emailUnlinkSuccessful } = useHandleUnlinkGoogleAccount();
    const { isLoading: isLinkingGoogleAccount, mutate: linkGoogleAccount, isSuccess: emailLinkSuccessful } = useHandleLinkGoogleAccount();
    const { isFetching: isFetchingUser, data: user, refetch: refetchUser } = useGetUser(props.currentUser.id, false);

    useEffect(() => {
        if (nurseries && nurseries.length > 0) {
            loadData();
        }

    }, [nurseries])

    useEffect(() => {
        refetchUser();

    }, [props.isOpen, emailLinkSuccessful]);

    useEffect(() => {
        setUnlinkEmailDialogOpen(false);
        refetchUser();

    }, [emailUnlinkSuccessful]);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    const loadData = () : void => {
        if (props.currentUser?.userNurseries && props.currentUser.userNurseries.length > 0) {
            const selectedNurseryOptions = nurseries?.filter(nur => selectedNurseries.some(localSelectedDiv => nur.id === localSelectedDiv.value))
                .map(OptionType.fromDataModel) ?? [];

            if (selectedNurseries.length < 1) return;

            setSelectedNurseryOptions(selectedNurseryOptions);
        } else {
            setSelectedNurseryOptions([]);
        }
    };

    const onGoogleLogInSuccess = async (response : IGoogleLoginSuccess) : Promise<void> => {
        linkGoogleAccount(response.code);
        refetchUser();
    };

    const onGoogleLogInFailure = (response : IGoogleLoginError) : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: response.error ?? 'Linking Error',
            ex: response,
        }));
    };

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const onUnlinkEmailClick = () => setUnlinkEmailDialogOpen(true);
    const onLogoutClick = () => setIsLogOutDialogOpen(true);

    const handleEmailUnlinking = () => {
        unlinkEmail();
    };

    const handleLogout = () => {
        props.onClose();
        props.onLogout();
    };

    const onNurseryChange = (selectedOptions : Array<IOptionType>) : void => {
        setSelectedNurseryOptions(selectedOptions);
        setSelectedNurseryOptions([]);
    };

    const onNurserySelectAll = () : void => {
        if (nurseryOptions.length !== selectedNurseryOptions.length) {
            onNurseryChange(nurseryOptions);
        } else {
            onNurseryChange([]);
        }
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/    
    
    const nurseryOptions = useMemo<Array<IOptionType>>(() => {
        if (!nurseries || !props.currentUser || !props.currentUser.userNurseries) return [];

        return nurseries
            .filter(nursery => nursery.isActive && props.currentUser.userNurseries.map(x => x.nursery?.id).includes(nursery.id))
            .map(OptionType.fromDataModel);

    }, [nurseries, props.currentUser]);


    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return (
        <>
            <ProjectDialog
                title={'User Settings'}
                isOpen={props.isOpen}
                maxWidth={'sm'}
                isLoadingCircular={isLoadingNurseries || isFetchingUser || isLinkingGoogleAccount}
                onClose={props.onClose}>
                <div className={'flx1 fdc aic jcc m10'}>
                    <MuiAutocompleteSelect
                        isMulti
                        className={'w300 mt30'}
                        name={'nurseries'}
                        label={'Nurseries'}
                        options={nurseryOptions}
                        value={selectedNurseryOptions}
                        onChange={onNurseryChange}
                    />
                    <Button size={'small'} onClick={onNurserySelectAll} disabled={nurseryOptions.length < 1}>
                        {(nurseryOptions.length > 0 && nurseryOptions.length === selectedNurseryOptions.length) ? 'Unselect All' : 'Select All'}
                    </Button>
                </div>
                <div className={'flx1 fdc aic jcc m10'}>
                    <div className={'fdc h100 flx1 aic jcc mb10'}>
                        <div className={'fdr aic'}>
                            {
                                user?.email == null 
                                    ? <div className={'zi1'}> 
                                        <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
                                            <GoogleSignInButton          
                                                onGoogleSignInSuccess={onGoogleLogInSuccess}
                                                onGoogleSignInFailure={onGoogleLogInFailure}   
                                                buttonText={'Link Google Account'}                                   
                                            />
                                        </GoogleOAuthProvider>
                                    </div>
                                    : <div className={'fdc aic jcc'}>
                                        <div className={'fdr aic jcc'}>
                                            <Typography className={'fdr m10'}>Linked Email:</Typography>
                                            <Typography variant={'body1'} className={'fdr fwb cpd m10'}>{ user.email }</Typography>
                                            <CustomTooltip title={'Unlink Email'}>
                                                <IconButton className={'cr'} onClick={onUnlinkEmailClick}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </CustomTooltip>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className={'fdr aic pb20'}>
                        <PillButton
                            className={'h35 w150'}
                            text={'LOGOUT'}
                            color={'secondary'}
                            onClick={onLogoutClick}
                        />
                    </div>
                </div>
                <ConfirmationDialog 
                    open={unlinkEmailDialogOpen}
                    title={'Unlink Email'}
                    isLoading={isUnlinkingEmail}
                    description={'Are you sure you would like to unlink the email address currently associated with your account?'}
                    onAccept={handleEmailUnlinking}
                    onClose={() => setUnlinkEmailDialogOpen(false)}
                    dialogType={'orange'}
                />
                <ConfirmationDialog
                    isLoading={false}
                    open={isLogOutDialogOpen}
                    title={'Log Out?'}
                    description={'Are you sure you would like to logout?'}
                    onAccept={handleLogout}
                    onClose={() => setIsLogOutDialogOpen(false)}
                    dialogType={'red'}
                />
            </ProjectDialog>
        </>
    );
};

export default UserSettings;