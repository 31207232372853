import React, { Suspense, lazy } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router';
import { useAppSelector } from '../../@types/redux';
import { IBaseUserRight, Loader, NoRightsScreen, Screen } from '@zz2/zz2-ui';
import { RightGrantsEnum } from '../../@types/enum/rightGrantsEnum';

const PrivateRoute = (props : RouteProps) : JSX.Element => {
    const session = useAppSelector(x => x.auth.session);
    const { component, ...rest } = props;

    const hasMenuRight = (url : string) : boolean => {
        if (!session) return false;
        if (session.user.userRights.length < 1) return false;

        const userRights : Array<IBaseUserRight> = session.user.userRights;
        if (userRights.length < 1) {
            return false;
        } else {
            const urlSections = url.split('/'); 
            const firstPartition = urlSections.slice(0, -1).join('/');
            const filteredUserRights : Array<IBaseUserRight> = userRights.filter(x => !!x.isActive && x.right.url === (urlSections[urlSections.length - 1].includes('Id') 
                ? firstPartition
                : url
            ));

            if (filteredUserRights.length > 0 && filteredUserRights.some(x => x.rightGrantLevel === RightGrantsEnum.View)) {
                return true;
            } else {
                return false;
            }
        }
    };

    const render = (routerProps : RouteComponentProps<any>) : React.ReactNode => {
        if (session) {
            if (!component) return;

            const Component = component;
            if (!hasMenuRight(routerProps.match.url)) {
                return <NoRightsScreen openSupportTicket={() => console.log('test')}/>;
            }
            
            return <Component {...routerProps} />;
        }

        return <Redirect to={{ pathname: '/login', state: { from: routerProps.location } }} />;
    };

    return (<Route {...rest} render={render} />);
};

{/* Route Imports */}
const Home = lazy(() => import('../home/Home'));

{/* Intake */}
const IntakeManager = lazy(() => import('../intake/intakeManagement/IntakeManager'));

{/* Stock */}
const TransferManager = lazy(() => import('../stock/transfer/TransferManager'));

{/* Rights */}
const UserManager = lazy(() => import('../right/userManagement/UserManager'));
const RightsManagement = lazy(() => import('../right/rightsManagement/RightsManager'));

{/* Master Data */}
const MasterDataHome = React.lazy(() => import('../masterData/masterDataHome'));
const AccountManager = React.lazy(() => import('../masterData/account/AccountManager'));
const IdentifierRangeManager = React.lazy(() => import('../masterData/identifierRange/IdentifierRangeManager'));
const EmployeeManager = React.lazy(() => import('../masterData/auth/employee/EmployeeManager'));
const EmployeeCardManager = React.lazy(() => import('../masterData/auth/employeeCard/EmployeeCardManager'));
const LocationManager = React.lazy(() => import('../masterData/location/LocationManager'));
const LocationLineTypeManager = React.lazy(() => import('../masterData/locationLineType/LocationLineTypeManager'));
const MethodManager = React.lazy(() => import('../masterData/method/MethodManager'));
const NurseryManager = React.lazy(() => import('../masterData/nursery/NurseryManager'));
const ProcessManager = React.lazy(() => import('../masterData/process/ProcessManager'));
const ProductManager = React.lazy(() => import('../masterData/product/ProductManager'));
const ProductTypeManager = React.lazy(() => import('../masterData/productType/ProductTypeManager'));
const ProfileManager = React.lazy(() => import('../masterData/profile/ProfileManager'));
const ReasonManager = React.lazy(() => import('../masterData/reason/ReasonManager'));
const SupplierManager = React.lazy(() => import('../masterData/supplier/SupplierManager'));
const UnitOfMeasureManager = React.lazy(() => import('../masterData/unitOfMeasure/UnitOfMeasureManager'));
const UnitOfMeasureTypeManager = React.lazy(() => import('../masterData/unitOfMeasureType/UnitOfMeasureTypeManager'));

{/* FARM Master Data */}
const DivisionManager = lazy(() => import('../masterData/FARM/division/DivisionManager'));
const SubdivisionManager = lazy(() => import('../masterData/FARM/subdivision/SubdivisionManager'));
const DepartmentManager = lazy(() => import('../masterData/FARM/department/DepartmentManager'));
const CommodityManager = lazy(() => import('../masterData/FARM/commodity/CommodityManager'));
const FieldManager = lazy(() => import('../masterData/FARM/field/FieldManager'));
const BlockManager = lazy(() => import('../masterData/FARM/block/BlockManager'));
const RootstockManager = lazy(() => import('../masterData/FARM/rootstock/RootstockManager'));
const VarietyManager = lazy(() => import('../masterData/FARM/variety/VarietyManager'));

{/* Development Tools */}
const LogManager = React.lazy(() => import('../devTools/logging/LogManager'));
const ContactUsManager = React.lazy(() => import('../devTools/contactUs/ContactUsManager'));
const NewsManager = React.lazy(() => import('../devTools/news/NewsManager'));
const FrequentlyAskedQuestionManager = React.lazy(() => import('../devTools/frequentlyAskedQuestion/FrequentlyAskedQuestionManager'));


const Routes = () : React.ReactElement => {
    return (
        <Suspense fallback={<Screen><Loader/></Screen>}>
            <Switch>
                <Route
                    path={'/'} exact
                    render={() => <Redirect from={'/'} to={{ pathname: '/home' }} />}
                />
                
                <PrivateRoute exact path='/home' component={Home} />

                {/* Intake */}
                <PrivateRoute exact path='/intake/intakeManagement' component={IntakeManager} />

                {/* Stock */}
                <PrivateRoute exact path='/stock/transferManagement' component={TransferManager} />

                {/* Master Data */}
                <PrivateRoute exact path='/masterData' component={MasterDataHome} />
                <PrivateRoute exact path='/masterData/account' component={AccountManager} />
                <PrivateRoute exact path='/masterData/identifierRange' component={IdentifierRangeManager} />
                <PrivateRoute exact path='/masterData/employee' component={EmployeeManager} />
                <PrivateRoute exact path='/masterData/employeeCard' component={EmployeeCardManager} />
                <PrivateRoute exact path='/masterData/location' component={LocationManager} />
                <PrivateRoute exact path='/masterData/locationLineType' component={LocationLineTypeManager} />
                <PrivateRoute exact path='/masterData/method' component={MethodManager} />
                <PrivateRoute exact path='/masterData/nursery' component={NurseryManager} />
                <PrivateRoute exact path='/masterData/process' component={ProcessManager} />
                <PrivateRoute exact path='/masterData/product' component={ProductManager} />
                <PrivateRoute exact path='/masterData/productType' component={ProductTypeManager} />
                <PrivateRoute exact path='/masterData/profile' component={ProfileManager} />
                <PrivateRoute exact path='/masterData/reason' component={ReasonManager} />
                <PrivateRoute exact path='/masterData/supplier' component={SupplierManager} />
                <PrivateRoute exact path='/masterData/unitOfMeasure' component={UnitOfMeasureManager} />
                <PrivateRoute exact path='/masterData/unitOfMeasureType' component={UnitOfMeasureTypeManager} />

                {/* FARM Master Data */}
                <PrivateRoute exact path='/masterData/division' component={DivisionManager} />
                <PrivateRoute exact path='/masterData/subdivision' component={SubdivisionManager} />
                <PrivateRoute exact path='/masterData/department' component={DepartmentManager} />
                <PrivateRoute exact path='/masterData/commodity' component={CommodityManager} />
                <PrivateRoute exact path='/masterData/field' component={FieldManager} />
                <PrivateRoute exact path='/masterData/block' component={BlockManager} />
                <PrivateRoute exact path='/masterData/rootstock' component={RootstockManager} />
                <PrivateRoute exact path='/masterData/variety' component={VarietyManager} />

                {/* Rights */}
                <PrivateRoute exact path='/rights/userManagement' component={UserManager} />
                <PrivateRoute exact path='/rights/rightsManagement' component={RightsManagement} />
                
                {/* Development Tools */}
                <PrivateRoute exact path='/development/tools' component={LogManager} />
                <PrivateRoute exact path='/development/contactUsManager' component={ContactUsManager} />
                <PrivateRoute exact path='/development/newsManager' component={NewsManager} />
                <PrivateRoute exact path='/development/faqManager' component={FrequentlyAskedQuestionManager} />

            </Switch>
        </Suspense>
    );
};

export default Routes;