import { IBaseUserRight } from '@zz2/zz2-ui';
import { RIGHT_GRANTS } from '../../appConstants';
import { IUser } from '../../@types/model/right/user/userModel';
import { getUserSelectedNurseriesLocalStorage } from '../localStorage/localStorageService';

export default class UserHelper {

    public static getUserDivisionHeading = (user : IUser) : string => {
        const userDivisionIds = user.userNurseries.filter(x => x.isActive && x.nursery?.isActive).map(x => x.nurseryId);
        if (userDivisionIds.length < 1) return 'NO NURSERY SELECTED';
        const selectedDivisions = getUserSelectedNurseriesLocalStorage();
        
        const selectedDivisionCount = selectedDivisions.length;
     
        switch (selectedDivisionCount) {
            case 0:
                return 'NO NURSERIES SELECTED';
            case 1:
                return selectedDivisions[0].label;
            case userDivisionIds.length:
                return 'ALL NURSERIES';
            default:
                return 'MULTIPLE NURSERIES';
        }
    };

    public static userSpecificRightGrantLevels = (rightCode : string, userRights ?: Array<IBaseUserRight>) => {
        const userHasRights = userRights?.filter(x => x.isActive && x.right.code === rightCode) ?? [];

        return {
            viewRight: userHasRights.some(x => x.rightGrantLevel === RIGHT_GRANTS.View),
            addRight: userHasRights.some(x => x.rightGrantLevel === RIGHT_GRANTS.Add),
            editRight: userHasRights.some(x => x.rightGrantLevel === RIGHT_GRANTS.Edit),
            deleteRight: userHasRights.some(x => x.rightGrantLevel === RIGHT_GRANTS.Delete),
            adminRight: userHasRights.some(x => x.rightGrantLevel === RIGHT_GRANTS.Admin)
        };
    }

    public static userRelatedRights = (pathname : string, userRights ?: Array<IBaseUserRight>) => {
        const userHasRights = userRights?.filter(x => x.isActive && x.right.url !== null && x.right.url.toLowerCase() === pathname.toLowerCase()) ?? [];

        return {
            viewRight: userHasRights.some(x => x.rightGrantLevel === RIGHT_GRANTS.View),
            addRight: userHasRights.some(x => x.rightGrantLevel === RIGHT_GRANTS.Add),
            editRight: userHasRights.some(x => x.rightGrantLevel === RIGHT_GRANTS.Edit),
            deleteRight: userHasRights.some(x => x.rightGrantLevel === RIGHT_GRANTS.Delete),
            adminRight: userHasRights.some(x => x.rightGrantLevel === RIGHT_GRANTS.Admin)
        };
    }

    public static getRelatedRightToolTipMessage = (dataType : string, level : RIGHT_GRANTS) : string => {
        let rightLevel = '';
        switch (level) {
            case (RIGHT_GRANTS.Add):
                rightLevel = 'Add';
                break;

            case (RIGHT_GRANTS.Edit):
                rightLevel = 'Edit';
                break;

            case (RIGHT_GRANTS.Delete):
                rightLevel = 'Delete';
                break;

            default:
                break;
        }

        return `Right: ${dataType} with Level: ${rightLevel} is required to perform this action. \n\nClick to request permission.'`;
    }
}