import axios from 'axios';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';
import { INursery } from '../../../@types/model/masterData/nursery/nursery';
import { INurseryUpsert } from '../../../@types/model/masterData/nursery/nurseryUpsert';

export default class NurseryHttpService {

    public static getList = async (signal ?: AbortSignal) : Promise<Array<INursery>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/Nursery/GetList`, {
            signal
        });

        return result.data;
    }

    public static upsert = async (nurseryUpsert : INurseryUpsert, type : UpsertTypeEnum) : Promise<INursery> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/Nursery/${type}`, nurseryUpsert);

        return result.data;
    }
    
    public static delete = async (nurseryId : number) : Promise<void> => {
        const result = await axios.delete(`${API_URL}/v1/MasterData/Nursery/Delete`, {
            params: {
                nurseryId,
            },
        });

        return result.data;
    }
}
