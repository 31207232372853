import { IBaseModel, IOptionType } from '@zz2/zz2-ui';

export default class OptionType {
    public static fromDataModel = function<T extends IBaseModel>(dataModel : T) : IOptionType {
        let labelNameOrDescription = '';

        if ('name' in dataModel && typeof dataModel.name === 'string') {
            labelNameOrDescription += `${dataModel.name} `;
        }

        if ('code' in dataModel && typeof dataModel.code === 'string') {
            labelNameOrDescription += `${dataModel.code} `;
        }

        if ('description' in dataModel && typeof dataModel.description === 'string') {
            labelNameOrDescription += `${dataModel.description} `;
        }

        return {
            label: `${labelNameOrDescription}`,
            value: dataModel.id,
        };
    }

    public static getEnumOptions = <T extends Record<string, string | number>>(enumObj : T) : Array<IOptionType> => {
        return Object.keys(enumObj)
            .filter(key => isNaN(Number(key))) 
            .map(key => {
                return {
                    label: key,
                    value: enumObj[key as keyof T] as string | number,
                };
            });
    };
}